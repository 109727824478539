module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alpine White | Teeth Whitening Strips | Whitestrips | Bleaching ","short_name":"Alpine White | Teeth Whitening Strips | Whitestrips | Bleaching ","description":"Selbst für ein strahlendes Lächeln sorgen. Alpine White bietet ein komplettes Set für Reinigung, Bleaching und Pflege. Entdecke unsere Whitening Strips.","background_color":"#ffffff","theme_color":"#316BAB","display":"fullscreen","icon":"src/images/favicon.png","start_url":"/","lang":"cn","localize":[],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6920960760db51bf20b05a78e25626d4"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
