exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/article/article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-article-overview-tsx": () => import("./../../../src/templates/article/overview.tsx" /* webpackChunkName: "component---src-templates-article-overview-tsx" */),
  "component---src-templates-content-tsx": () => import("./../../../src/templates/content.tsx" /* webpackChunkName: "component---src-templates-content-tsx" */),
  "component---src-templates-faq-category-tsx": () => import("./../../../src/templates/faq/category.tsx" /* webpackChunkName: "component---src-templates-faq-category-tsx" */),
  "component---src-templates-faq-overview-tsx": () => import("./../../../src/templates/faq/overview.tsx" /* webpackChunkName: "component---src-templates-faq-overview-tsx" */),
  "component---src-templates-faq-question-tsx": () => import("./../../../src/templates/faq/question.tsx" /* webpackChunkName: "component---src-templates-faq-question-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-location-location-tsx": () => import("./../../../src/templates/location/location.tsx" /* webpackChunkName: "component---src-templates-location-location-tsx" */),
  "component---src-templates-location-overview-tsx": () => import("./../../../src/templates/location/overview.tsx" /* webpackChunkName: "component---src-templates-location-overview-tsx" */),
  "component---src-templates-location-partner-tsx": () => import("./../../../src/templates/location/partner.tsx" /* webpackChunkName: "component---src-templates-location-partner-tsx" */),
  "component---src-templates-shop-cart-tsx": () => import("./../../../src/templates/shop/cart.tsx" /* webpackChunkName: "component---src-templates-shop-cart-tsx" */),
  "component---src-templates-shop-category-tsx": () => import("./../../../src/templates/shop/category.tsx" /* webpackChunkName: "component---src-templates-shop-category-tsx" */),
  "component---src-templates-shop-product-tsx": () => import("./../../../src/templates/shop/product.tsx" /* webpackChunkName: "component---src-templates-shop-product-tsx" */),
  "component---src-templates-shop-shop-tsx": () => import("./../../../src/templates/shop/shop.tsx" /* webpackChunkName: "component---src-templates-shop-shop-tsx" */),
  "component---src-templates-static-tsx": () => import("./../../../src/templates/static.tsx" /* webpackChunkName: "component---src-templates-static-tsx" */),
  "component---src-templates-treatment-addon-overview-tsx": () => import("./../../../src/templates/treatment/addon/overview.tsx" /* webpackChunkName: "component---src-templates-treatment-addon-overview-tsx" */),
  "component---src-templates-treatment-addon-treatment-addon-tsx": () => import("./../../../src/templates/treatment/addon/treatmentAddon.tsx" /* webpackChunkName: "component---src-templates-treatment-addon-treatment-addon-tsx" */),
  "component---src-templates-treatment-overview-tsx": () => import("./../../../src/templates/treatment/overview.tsx" /* webpackChunkName: "component---src-templates-treatment-overview-tsx" */),
  "component---src-templates-treatment-treatment-tsx": () => import("./../../../src/templates/treatment/treatment.tsx" /* webpackChunkName: "component---src-templates-treatment-treatment-tsx" */)
}

